import React, { Component } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	TextInput,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";

function formatAmount(amount) {
	if (amount === undefined || amount === null) return "0.00";
	const number = parseFloat(amount);
	if (isNaN(number)) return "0.00";
	return number.toFixed(2);
}

export default class EditBudgetEntry extends Component {
	constructor(props) {
		super(props);
		const entry = props.entry;
		this.state = {
			brand: entry.brand,
			allocation: entry.allocation,
			pl_allocation: entry.pl_allocation,
			spend: { ...entry.spend },
			loading: false,
			error: "",
			activeMonth: null,
			editingAmount: "",
		};
	}

	handleSubmit = () => {
		if (
			!this.state.brand ||
			!this.state.allocation ||
			!this.state.pl_allocation
		) {
			this.setState({ error: "Please fill in all required fields" });
			return;
		}

		const updatedEntry = {
			brand: this.state.brand,
			allocation: this.state.allocation,
			pl_allocation: this.state.pl_allocation,
			spend: this.state.spend,
		};

		this.props.onSubmit(updatedEntry, this.props.index);
	};

	handleMonthClick = (month) => {
		const currentAmount = this.state.spend?.[month] || 0;
		this.setState({
			activeMonth: month,
			editingAmount: formatAmount(currentAmount),
		});
	};

	handleAmountChange = (amount) => {
		this.setState({
			editingAmount: amount.replace(/[^0-9.]/g, ""),
		});
	};

	handleAmountSubmit = () => {
		if (this.state.activeMonth) {
			const newSpend = {
				...this.state.spend,
				[this.state.activeMonth]:
					parseFloat(this.state.editingAmount) || 0,
			};
			this.setState({
				spend: newSpend,
				activeMonth: null,
				editingAmount: "",
			});
		}
	};

	render() {
		const months = [
			"jan",
			"feb",
			"mar",
			"apr",
			"may",
			"jun",
			"jul",
			"aug",
			"sep",
			"oct",
			"nov",
			"dec",
		];

		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={true}
				onRequestClose={this.props.close}>
				<View style={styles.modalOverlay}>
					<View style={styles.modalContent}>
						<View style={styles.modalHeader}>
							<Text style={styles.modalTitle}>
								Edit Budget Entry
							</Text>
							<TouchableOpacity onPress={this.props.close}>
								<Text style={styles.closeButton}>×</Text>
							</TouchableOpacity>
						</View>

						<ScrollView style={styles.formContainer}>
							<View style={styles.inputGroup}>
								<Text style={styles.label}>Brand *</Text>
								<TextInput
									style={styles.input}
									value={this.state.brand}
									onChangeText={(text) =>
										this.setState({ brand: text })
									}
									placeholder='Enter brand name'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>Allocation *</Text>
								<TextInput
									style={styles.input}
									value={this.state.allocation}
									onChangeText={(text) =>
										this.setState({ allocation: text })
									}
									placeholder='Enter allocation'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>
									P&L Spend Allocation *
								</Text>
								<TextInput
									style={styles.input}
									value={this.state.pl_allocation}
									onChangeText={(text) =>
										this.setState({ pl_allocation: text })
									}
									placeholder='Enter P&L spend allocation'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>
									Monthly Amounts
								</Text>
								<View style={styles.monthsGrid}>
									{months.map((month) => (
										<TouchableOpacity
											key={month}
											style={[
												styles.monthCell,
												this.state.activeMonth ===
													month &&
													styles.monthCellActive,
											]}
											onPress={() =>
												this.handleMonthClick(month)
											}>
											<Text
												style={[
													styles.monthName,
													this.state.activeMonth ===
														month &&
														styles.monthNameActive,
												]}>
												{month.toUpperCase()}
											</Text>
											<Text
												style={[
													styles.monthAmount,
													this.state.activeMonth ===
														month &&
														styles.monthAmountActive,
												]}>
												R{" "}
												{formatAmount(
													this.state.spend?.[month],
												)}
											</Text>
										</TouchableOpacity>
									))}
								</View>
							</View>

							{this.state.activeMonth && (
								<View style={styles.amountEditContainer}>
									<Text style={styles.label}>
										Edit amount for{" "}
										{this.state.activeMonth.toUpperCase()}
									</Text>
									<View style={styles.amountEditRow}>
										<TextInput
											style={styles.amountInput}
											value={this.state.editingAmount}
											onChangeText={
												this.handleAmountChange
											}
											keyboardType='numeric'
											placeholder='Enter amount'
											autoFocus
										/>
										<TouchableOpacity
											style={styles.applyButton}
											onPress={this.handleAmountSubmit}>
											<Text
												style={styles.applyButtonText}>
												Apply
											</Text>
										</TouchableOpacity>
									</View>
								</View>
							)}

							{this.state.error ? (
								<Text style={styles.errorText}>
									{this.state.error}
								</Text>
							) : null}
						</ScrollView>

						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={styles.cancelButton}
								onPress={this.props.close}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={styles.submitButton}
								onPress={this.handleSubmit}
								disabled={this.state.loading}>
								{this.state.loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text style={styles.submitButtonText}>
										Save Changes
									</Text>
								)}
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = {
	// ... include all styles from AddBudgetEntry ...
	monthsGrid: {
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	monthCell: {
		width: "23%",
		padding: 10,
		backgroundColor: "#F5F5F5",
		borderRadius: 5,
		marginBottom: 8,
		alignItems: "center",
	},
	monthCellActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	monthName: {
		fontSize: 12,
		fontWeight: "600",
		marginBottom: 4,
	},
	monthAmount: {
		fontSize: 11,
	},
	amountEditContainer: {
		marginTop: 20,
		padding: 15,
		backgroundColor: "#F5F5F5",
		borderRadius: 5,
	},
	amountEditRow: {
		flexDirection: "row",
		alignItems: "center",
	},
	amountInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 5,
		padding: 10,
		fontSize: 14,
		backgroundColor: "#FFF",
		marginRight: 10,
	},
	applyButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 10,
		borderRadius: 5,
	},
	applyButtonText: {
		color: "#FFF",
		fontSize: 14,
	},
	monthNameActive: {
		color: "#FFF",
	},
	monthAmountActive: {
		color: "#FFF",
	},
};
