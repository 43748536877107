import React, { Component } from "react";
import {
	View,
	Text,
	Modal,
	TouchableOpacity,
	TextInput,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

function formatAmount(amount) {
	if (amount === undefined || amount === null) return "0.00";
	const number = parseFloat(amount);
	if (isNaN(number)) return "0.00";
	return number.toFixed(2);
}

// Add helper function for quarter calculations
function calculateQuarterTotal(spend, months) {
	return months.reduce((sum, month) => {
		const value = spend[month];
		if (value === undefined || value === null) return sum;
		const number = parseFloat(value);
		return sum + (isNaN(number) ? 0 : number);
	}, 0);
}

export default class AddBudgetEntry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			brand: "",
			allocation: "",
			pl_allocation: "",
			spend: {
				jan: 0,
				feb: 0,
				mar: 0,
				apr: 0,
				may: 0,
				jun: 0,
				jul: 0,
				aug: 0,
				sep: 0,
				oct: 0,
				nov: 0,
				dec: 0,
			},
			loading: false,
			error: "",
			activeMonth: null,
			editingAmount: "",
		};
	}

	handleSubmit = async () => {
		if (
			!this.state.brand ||
			!this.state.allocation ||
			!this.state.pl_allocation
		) {
			this.setState({ error: "Please fill in all required fields" });
			return;
		}

		this.setState({ loading: true });

		const newEntry = {
			brand: this.state.brand,
			allocation: this.state.allocation,
			pl_allocation: this.state.pl_allocation,
			spend: this.state.spend,
		};

		try {
			await this.props.onSubmit(newEntry);
		} catch (error) {
			this.setState({
				error: "Failed to add budget entry",
				loading: false,
			});
		}
	};

	handleMonthClick = (month) => {
		const currentAmount = this.state.spend?.[month] || 0;
		this.setState({
			activeMonth: month,
			editingAmount: formatAmount(currentAmount),
		});
	};

	handleAmountChange = (amount) => {
		this.setState({
			editingAmount: amount.replace(/[^0-9.]/g, ""),
		});
	};

	handleAmountSubmit = () => {
		if (this.state.activeMonth) {
			const newSpend = {
				...this.state.spend,
				[this.state.activeMonth]:
					parseFloat(this.state.editingAmount) || 0,
			};
			this.setState({
				spend: newSpend,
				activeMonth: null,
				editingAmount: "",
			});
		}
	};

	render() {
		const quarters = [
			{
				name: "Q1",
				months: ["jan", "feb", "mar"],
			},
			{
				name: "Q2",
				months: ["apr", "may", "jun"],
			},
			{
				name: "Q3",
				months: ["jul", "aug", "sep"],
			},
			{
				name: "Q4",
				months: ["oct", "nov", "dec"],
			},
		];

		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={true}
				onRequestClose={this.props.close}>
				<View style={styles.modalOverlay}>
					<View style={styles.modalContent}>
						<View style={styles.modalHeader}>
							<Text style={styles.modalTitle}>
								Add Budget Entry
							</Text>
							<TouchableOpacity onPress={this.props.close}>
								<Text style={styles.closeButton}>×</Text>
							</TouchableOpacity>
						</View>

						<ScrollView style={styles.formContainer}>
							<View style={styles.inputGroup}>
								<Text style={styles.label}>Brand *</Text>
								<TextInput
									style={styles.input}
									value={this.state.brand}
									onChangeText={(text) =>
										this.setState({ brand: text })
									}
									placeholder='Enter brand name'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>Allocation *</Text>
								<TextInput
									style={styles.input}
									value={this.state.allocation}
									onChangeText={(text) =>
										this.setState({ allocation: text })
									}
									placeholder='Enter allocation'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>
									P&L Spend Allocation *
								</Text>
								<TextInput
									style={styles.input}
									value={this.state.pl_allocation}
									onChangeText={(text) =>
										this.setState({ pl_allocation: text })
									}
									placeholder='Enter P&L spend allocation'
								/>
							</View>

							<View style={styles.inputGroup}>
								<Text style={styles.label}>
									Quarterly Amounts
								</Text>
								{quarters.map((quarter) => (
									<View
										key={quarter.name}
										style={styles.quarterContainer}>
										<View style={styles.quarterHeader}>
											<Text style={styles.quarterName}>
												{quarter.name}
											</Text>
											<Text style={styles.quarterTotal}>
												Total: R{" "}
												{formatAmount(
													calculateQuarterTotal(
														this.state.spend,
														quarter.months,
													),
												)}
											</Text>
										</View>
										<View style={styles.monthsGrid}>
											{quarter.months.map((month) => (
												<TouchableOpacity
													key={month}
													style={[
														styles.monthCell,
														this.state
															.activeMonth ===
															month &&
															styles.monthCellActive,
													]}
													onPress={() =>
														this.handleMonthClick(
															month,
														)
													}>
													<Text
														style={[
															styles.monthName,
															this.state
																.activeMonth ===
																month &&
																styles.monthNameActive,
														]}>
														{month.toUpperCase()}
													</Text>
													<Text
														style={[
															styles.monthAmount,
															this.state
																.activeMonth ===
																month &&
																styles.monthAmountActive,
														]}>
														R{" "}
														{formatAmount(
															this.state.spend[
																month
															],
														)}
													</Text>
												</TouchableOpacity>
											))}
										</View>
									</View>
								))}
							</View>

							{this.state.activeMonth && (
								<View style={styles.amountEditContainer}>
									<Text style={styles.label}>
										Edit amount for{" "}
										{this.state.activeMonth.toUpperCase()}
									</Text>
									<View style={styles.amountEditRow}>
										<TextInput
											style={styles.amountInput}
											value={this.state.editingAmount}
											onChangeText={
												this.handleAmountChange
											}
											keyboardType='numeric'
											placeholder='Enter amount'
											autoFocus
										/>
										<TouchableOpacity
											style={styles.applyButton}
											onPress={this.handleAmountSubmit}>
											<Text
												style={styles.applyButtonText}>
												Apply
											</Text>
										</TouchableOpacity>
									</View>
								</View>
							)}

							<View style={styles.totalContainer}>
								<Text style={styles.totalLabel}>
									Total Budget
								</Text>
								<Text style={styles.totalAmount}>
									R{" "}
									{formatAmount(
										Object.values(this.state.spend).reduce(
											(sum, amount) =>
												sum + (parseFloat(amount) || 0),
											0,
										),
									)}
								</Text>
							</View>

							{this.state.error ? (
								<Text style={styles.errorText}>
									{this.state.error}
								</Text>
							) : null}
						</ScrollView>

						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={styles.cancelButton}
								onPress={this.props.close}>
								<Text style={styles.cancelButtonText}>
									Cancel
								</Text>
							</TouchableOpacity>

							<TouchableOpacity
								style={styles.submitButton}
								onPress={this.handleSubmit}
								disabled={this.state.loading}>
								{this.state.loading ? (
									<ActivityIndicator color='#FFF' />
								) : (
									<Text style={styles.submitButtonText}>
										Add Entry
									</Text>
								)}
							</TouchableOpacity>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}

const styles = {
	modalOverlay: {
		flex: 1,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		justifyContent: "center",
		alignItems: "center",
	},
	modalContent: {
		backgroundColor: "#FFF",
		borderRadius: 10,
		width: "90%",
		maxWidth: 500,
		maxHeight: "90%",
	},
	modalHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: 20,
		borderBottomWidth: 1,
		borderBottomColor: "#E8E8E8",
	},
	modalTitle: {
		fontSize: 18,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	closeButton: {
		fontSize: 24,
		color: APP_COLOURS.BG2,
		fontWeight: "600",
	},
	formContainer: {
		padding: 20,
	},
	inputGroup: {
		marginBottom: 20,
	},
	label: {
		fontSize: 14,
		color: APP_COLOURS.BG2,
		marginBottom: 8,
		fontWeight: "500",
	},
	input: {
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 5,
		padding: 10,
		fontSize: 14,
	},
	monthsGrid: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	monthCell: {
		width: "31%",
		padding: 10,
		backgroundColor: "#FFF",
		borderRadius: 5,
		marginBottom: 8,
		alignItems: "center",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.1,
		shadowRadius: 2,
		elevation: 2,
	},
	monthCellActive: {
		backgroundColor: APP_COLOURS.PURPLE,
	},
	monthName: {
		fontSize: 12,
		fontWeight: "600",
		marginBottom: 4,
	},
	monthNameActive: {
		color: "#FFF",
	},
	monthAmount: {
		fontSize: 11,
	},
	monthAmountActive: {
		color: "#FFF",
	},
	amountEditContainer: {
		marginTop: 20,
		padding: 15,
		backgroundColor: "#F5F5F5",
		borderRadius: 5,
	},
	amountEditRow: {
		flexDirection: "row",
		alignItems: "center",
	},
	amountInput: {
		flex: 1,
		borderWidth: 1,
		borderColor: "#E8E8E8",
		borderRadius: 5,
		padding: 10,
		fontSize: 14,
		backgroundColor: "#FFF",
		marginRight: 10,
	},
	applyButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 10,
		borderRadius: 5,
	},
	applyButtonText: {
		color: "#FFF",
		fontSize: 14,
	},
	buttonContainer: {
		flexDirection: "row",
		justifyContent: "flex-end",
		padding: 20,
		borderTopWidth: 1,
		borderTopColor: "#E8E8E8",
	},
	cancelButton: {
		padding: 10,
		paddingHorizontal: 20,
		marginRight: 10,
	},
	cancelButtonText: {
		color: APP_COLOURS.BG2,
		fontSize: 14,
	},
	submitButton: {
		backgroundColor: APP_COLOURS.PURPLE,
		padding: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
	},
	submitButtonText: {
		color: "#FFF",
		fontSize: 14,
	},
	errorText: {
		color: APP_COLOURS.RED,
		fontSize: 14,
		marginBottom: 10,
	},
	quarterContainer: {
		marginBottom: 20,
		backgroundColor: "#F8F9FA",
		borderRadius: 8,
		padding: 15,
	},
	quarterHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: 10,
		paddingBottom: 10,
		borderBottomWidth: 1,
		borderBottomColor: "#E8E8E8",
	},
	quarterName: {
		fontSize: 16,
		fontWeight: "600",
		color: APP_COLOURS.BG2,
	},
	quarterTotal: {
		fontSize: 14,
		fontWeight: "500",
		color: APP_COLOURS.PURPLE,
	},
	totalContainer: {
		marginTop: 20,
		padding: 15,
		backgroundColor: APP_COLOURS.BG2,
		borderRadius: 8,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	totalLabel: {
		fontSize: 16,
		fontWeight: "600",
		color: "#FFF",
	},
	totalAmount: {
		fontSize: 18,
		fontWeight: "700",
		color: "#FFF",
	},
};
