import moment from "moment";
import {
	api_crudCreate,
	api_crudRead,
	api_crudDelete,
	api_crudUpdate,
	api_getUsers,
	api_crudWithImage,
	api_crudDeleteDocument,
	api_sendBasicEmail,
	api_createPostApproval,
	api_crudReadUnass,
	api_creatSurveyFeedback,
	api_sendDiscordMessage,
	api_sendUserIdEmail,
	api_crudDeleteWhere,
	api_crudGetProjects,
	api_crudGetTasks,
	api_crudDeleteWithImage,
	api_crudWithHtml,
	api_crudDeleteProposalPage,
} from "./Api";

// THIS FILE IS to get and set async data of the device without making too many requests

export const crudCreate = async (collection, where, next) => {
	let url = api_crudCreate;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithBase64 = async (collection, where, next) => {
	let url = api_crudWithImage;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	//   collection: collection,
	//   data: where,
	// });

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudCreateWithHtml = async (collection, where, next) => {
	let url = api_crudWithHtml;
	// let url = "http://127.0.0.1:5001/tetricepm-5f972/us-central1/app/pm/cwhtml";
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// console.log({
	// 	collection: collection,
	// 	data: where,
	// });
	// return;

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			// if (response.status === 200) {
			response
				.json()
				.then((result) => {
					// console.log('result ', result);
					return next(200, result);
				})
				.catch((error) => {
					console.log(error);
					return next(response.status, []);
				});
			// } else {
			// 	// logOut();
			// 	// console.log()
			// 	return next(response.status);
			// }
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteImage = async (collection, where, next) => {
	let url = api_crudDeleteWithImage;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	console.log({
		collection: collection,
		data: where,
	});
	// return;
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteDocument = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// return;

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const deleteProposalPage = async (collection, where, next) => {
	let url = api_crudDeleteProposalPage;
	// let url =
	// 	"http://127.0.0.1:5001/tetricepm-5f972/us-central1/app/pm/deleteProposalPage";

	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	// return;

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				// logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteArray = async (collection, where, next) => {
	let url = api_crudDeleteDocument;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudRead = async (collection, where, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadUnass = async (collection, where, next) => {
	let url = api_crudReadUnass;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudReadAll = async (collection, next) => {
	let url = api_crudRead;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: "",
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status);
					});
			} else {
				logOut();
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudUpdate = async (collection, obj, next) => {
	let url = api_crudUpdate;
	let user_data = await getUserData();
	let updatedAt = moment().unix();
	obj.updatedAt = updatedAt;
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				console.log("result ");
				return next(200);
			} else if (response.status === 401) {
				logOut();
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDelete = async (collection, obj, next) => {
	let url = api_crudDelete;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			data: obj,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			if (response.status !== 200) {
				logOut();
				return;
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudDeleteWhere = async (collection, where, next) => {
	let url = api_crudDeleteWhere;
	let user_data = await getUserData();
	let requestOptions = {
		method: "DELETE",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({
			collection: collection,
			where: where,
		}),
		redirect: "follow",
	};
	await console.log(`requesting ${url} => ${collection}`);
	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			if (response.status !== 200) {
				logOut();
				return;
			} else {
				return next(response.status);
			}
		})
		.catch((error) => console.log("error", error));
};

//

export const crudGetProjects = async (obj, next) => {
	let url = api_crudGetProjects;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify(obj),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				// logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudGetTasks = async (next) => {
	let url = api_crudGetTasks;
	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: JSON.stringify({}),
		redirect: "follow",
	};

	await console.log(url, requestOptions);
	await fetch(url, requestOptions)
		.then((response) => {
			console.log("crudRead", response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						// console.log('result ', result);
						return next(200, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				// logOut();
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudSendEmail = async (sender, recipient, subject, body) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendBasicEmail;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		sender: sender,
		recipient: recipient,
		body: `${body}`,
		subject: `${subject}`,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	// fetch(url, requestOptions)
	fetch(
		"https://workflow.tetrice.co.za/webhook-test/7064d697-121d-4a41-bff1-06dd08ec591f",
		requestOptions,
	)
		.then((response) => {
			console.log(response.status);
			if (response.status !== 200) {
				// alert("Error sending email!");
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

export const crudSendUserIdEmail = async (
	sender,
	recipient,
	subject,
	title,
	body,
	text,
	avatar,
	skrumlink,
) => {
	let user_data = await getUserData();
	let url = api_sendUserIdEmail;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		sender: sender,
		id: recipient,
		body: `${body}`,
		text,
		subject: `${subject}`,
		title,
		avatar,
		skrumlink,
		senderName: `${user_data.first_name} ${user_data.last_name}`,
	});

	var requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(
		"https://workflow.tetrice.co.za/webhook/7064d697-121d-4a41-bff1-06dd08ec591f",
		requestOptions,
	)
		.then((response) => {
			console.log(response.status);
			if (response.status !== 200) {
				alert("Error sending email!");
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendDiscordMessage = async (username, message, avatar_url, id) => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");

	let url = api_sendDiscordMessage;

	var raw = JSON.stringify({
		jwt: "dnYESWgXMYn5WVgdUjREDqvuzezfDn9WuYQRDNIFNggEVYGNVEu",
		message: message,
		username: username,
		avatar_url: avatar_url,
		id: id,
	});

	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow",
	};
	// console.log(url, requestOptions);
	// return;
	fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status !== 200) {
				// alert("Error updating email!");
				// logOut();
			} else if (response.status === 200) {
				// alert("Sent!");

				return;
			}
		})
		.catch((error) => console.log("error", error));
};

//
//

async function getUserData() {
	try {
		const value = await localStorage.getItem("user_data");
		let parse = JSON.parse(value);
		if (value !== null) {
			return parse;
		} else {
			return;
		}
	} catch (error) {
		return;
	}
}

async function removeAsyncItem(key) {
	try {
		await localStorage.removeItem(key);
		return true;
	} catch (exception) {
		alert("Error removing item");
		return false;
	}
}

// async function onValueChange(item, selectedValue) {
// 	try {
// 		await localStorage.setItem(item, selectedValue).then((x) => {
// 			// console.log('onValueChange', item, selectedValue);
// 		});
// 	} catch (error) {
// 		console.error(error);
// 	}
// }

export const createPostApproval = async (data, next) => {
	await console.log("createPostApproval");

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${data.token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_createPostApproval, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};

export const createSurveyApproval = async (data, next) => {
	// await console.log("createSurveyApproval");
	// alert("No API set up");

	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${data.token}`,
		},

		redirect: "follow",
		body: JSON.stringify(data),
	};

	// console.log(data.token);
	// return;
	fetch(api_creatSurveyFeedback, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						return next(response.status);
					})
					.catch((error) => {
						return next(response.status);
					});
			} else {
				return next(response.status);
			}
		})
		.catch((error) => {
			console.log(error);
			return next(500);
		});
};
//
//
//
//
export const crudGetUsers = async (next) => {
	await console.log("crudGetUsers");
	const responseData = await getUsers((d) => {
		// console.log('response =====================', d);
		return next(d);
	});
};
async function getUsers(next) {
	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},

		redirect: "follow",
	};
	fetch(api_getUsers, requestOptions)
		.then((response) => {
			if (response.status == 200) {
				response
					.json()
					.then(async (r) => {
						// const set_ = await onValueChange(
						// 	"users",
						// 	,
						// );
						localStorage.setItem("users", JSON.stringify(r));
						return next(r);
					})
					.catch((error) => {
						return [];
					});
			} else {
				console.log(response);
				return next([]);
			}
		})
		.catch((error) => {
			console.log(error);
			return next([]);
		});
}

export const sendSingleJarvisMessage = async (message, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/9a6b020f-ff7d-4f6b-8b0a-b4691cdb9410";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer 1234`,
		},
		redirect: "follow",
		body: JSON.stringify(message),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, [
							{
								text: "Error",
								date: moment().toISOString(),
								from: "Jarvis",
							},
						]);
					});
			} else {
				return next(response.status, [
					{
						text: "Error",
						date: moment().toISOString(),
						from: "Jarvis",
					},
				]);
			}
		})
		.catch((error) => console.log("error", error));
};

export const createPresentation = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/ab860d5cc-6e8e-4542-8566-af350fe44694";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const getPresentations = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/a328232ee-41dc-484d-8f4b-aa7aaa3c089e";

	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const fetchPresentationSharedList = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/emailList-cfd8-46fe-8f5d-1fba77801335";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const newCreativeRequest = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook-test/e63caa07-1a51-4074-9de2-4235aba91a6f";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const emailPresentation = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/1becbad4-fb91-4f88-b842-610fe077694b";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	fetch(url, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			return response.json();
		})
		.then((data) => {
			console.log(data);
			next();
		})
		.catch((error) => {
			next();
		});
};

//
//
//

export const getProposals = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/328232ee-41dc-484d-8f4b-aa7aaa3c089e";

	let user_data = await getUserData();
	let requestOptions = {
		method: "GET",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const createProposal = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/b860d5cc-6e8e-4542-8566-af350fe44694";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const fetchNotices = async (next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/692ba9f4-71cf-438c-b8ee-f73252eb1a40";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify({}),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const createNotice = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/8c10b86e-61ee-4ab7-b183-bfda86ae8537";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const emailProposal = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/8becbad4-fb91-4f88-b842-610fe077694b";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	fetch(url, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			return response.json();
		})
		.then((data) => {
			console.log(data);
			next();
		})
		.catch((error) => {
			next();
		});
};

export const fetchProjectsLastUpdated = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/caba3ee1-713d-41ee-a1f0-f03ce569453a";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	fetch(url, requestOptions)
		.then((response) => {
			if (!response.ok) {
				throw new Error("Failed to fetch data");
			}
			return response.json();
		})
		.then((data) => {
			console.log(data);
			next(data);
		})
		.catch((error) => {
			next([]);
		});
};

export const updatePresentationDocument = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/fa683270-ffec-4542-beff-a2acafd63bfd";

	let user_data = await getUserData();
	let requestOptions = {
		method: "PUT",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const fetchCampaignsPerBrand = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/d6405ce1-a98c-4065-888b-54adcc9ef795";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				response
					.json()
					.then((result) => {
						return next(response.status, result);
					})
					.catch((error) => {
						console.log(error);
						return next(response.status, []);
					});
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const createCampaignsPerBrand = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/b48f7753-8aa4-4b11-9a95-73e28022153a";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				return next(response.status, []);
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const sendCampaignForApproval = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/6ba4d5b0-a5a2-4138-9a7d-97e29f3bfb83";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				return next(response.status, []);
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

export const deleteCampaignsPerBrand = async (obj, next) => {
	let url =
		"https://workflow.tetrice.co.za/webhook/f456b856-e845-411c-82a5-349ea11e1575";

	let user_data = await getUserData();
	let requestOptions = {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Origin: "",
			dataType: "json",
			authorization: `Bearer ${user_data.accessToken}`,
		},
		redirect: "follow",
		body: JSON.stringify(obj),
	};

	await fetch(url, requestOptions)
		.then((response) => {
			console.log(response);
			if (response.status === 200) {
				return next(response.status, []);
			} else {
				return next(response.status, []);
			}
		})
		.catch((error) => console.log("error", error));
};

async function logOut() {
	localStorage.clear();
	window.location.href = "/";
}
